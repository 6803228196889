import { isAndroid, isIOS, isMobile } from 'react-device-detect'
import { isReactNativeWebView } from './react-native'

const isBrowser =
  typeof window !== 'undefined' && typeof document !== 'undefined'

const amplitude = isBrowser ? require('@amplitude/analytics-browser') : null

/*
 ** Analytics Manager singleton
 */
class AnalyticsManager {
  private _instance
  private _identify
  private _appendEventProps

  constructor() {
    if (!this._instance) {
      this._instance = this

      if (isBrowser) {
        amplitude.init(process.env.AMPLITUDE_SDK_API_KEY, null, {
          defaultTracking: false,
          minIdLength: 1,
        })
        this._identify = new amplitude.Identify()
      }

      if (isMobile) {
        this._appendEventProps = {
          '[Kickoff] OS': isIOS ? 'iOS' : isAndroid ? 'Android' : null,
          '[Kickoff] Platform': isReactNativeWebView()
            ? 'Mobile Webview'
            : 'Web',
        }
      } else {
        this._appendEventProps = {
          '[Kickoff] OS': 'Desktop',
          '[Kickoff] Platform': 'Web',
        }
      }
    }

    return this._instance
  }

  logEvent(name: string, properties: object = {}) {
    amplitude.track(name, { ...properties, ...this._appendEventProps })
  }

  identifyUser({ me }) {
    if (!me) return

    if (me.user) {
      const {
        user: { id, email },
      } = me

      if (me.user.coach) {
        const coach = me.user.coach
        this._identify.set('coachId', coach.id)
        this._identify.set('coachLevel', coach.levelId)
        this._identify.set('userType', 'coach')
      } else {
        this._identify.set('userType', 'client')
      }

      amplitude.setUserId(id)
      this._identify.set('email', email)
      amplitude.identify(this._identify)
    }
  }

  setUserProperties(properties: object) {
    Object.keys(properties).forEach(key => {
      this._identify.set(key, properties[key])
    })
    amplitude.identify(this._identify)
  }

  setTotalActiveClients(totalActiveClients) {
    this._identify.set('Total Active Clients', totalActiveClients)
    amplitude.identify(this._identify)
  }

  setDeviceId(deviceId) {
    amplitude.setDeviceId(deviceId)
  }
}

const instance = new AnalyticsManager()
Object.freeze(instance)

export default instance
