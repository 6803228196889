import { useEffect, useState } from 'react'

const DEFAULT_INSETS = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}

type InsetsType = {
  top: number
  right: number
  bottom: number
  left: number
}

// takes useSafeAreaInsets() value from the mobile app when rendered in a WebView
export const useSafeAreaInsets = (): InsetsType => {
  const [insets, setInsets] = useState<InsetsType>(DEFAULT_INSETS)

  useEffect(() => {
    setInsets(
      (window as { safeAreaInsets?: InsetsType }).safeAreaInsets ||
        DEFAULT_INSETS
    )
  }, [])

  return insets
}
