export const pageview = url => {
  window?.dataLayer?.push({
    event: 'pageview',
    page: url,
  })
}

export const pageCategory = pc => {
  window?.dataLayer?.push({
    pageCategory: pc,
    event: pc,
  })
}
