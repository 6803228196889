export const isReactNativeWebView = () => {
  return !!(window as any)?.ReactNativeWebView
}

export const maybePostReactNativeWebViewMessage = message => {
  if (isReactNativeWebView()) {
    window.ReactNativeWebView.postMessage(message)
    return true
  }
  return false
}
