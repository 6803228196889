import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ExperimentKit } from '.'
import { ExperimentKitSSRData } from './server'
import {
  AssignedExperiments,
  ExperimentResult,
  JSONValue,
  WidenPrimitives,
} from './types'
import { parseCookies } from 'nookies'

export type ExperimentKitContextValue = {
  experimentKit?: ExperimentKit
}

export const ExperimentKitContext = createContext<ExperimentKitContextValue>({})

function experiment<T extends JSONValue = any>(
  key: string,
  experimentKit: ExperimentKit
): ExperimentResult<T | null> {
  if (!experimentKit) {
    return {
      value: null,
      on: false,
      off: true,
      source: 'unknownExperiment',
    }
  }

  return experimentKit.evalExperiment<T>(key)
}

export function useExperimentKit(
  data?: ExperimentKitSSRData,
  preAssignedSSRExperiments?: AssignedExperiments
) {
  const { experimentKit } = useContext(ExperimentKitContext)
  const isFirst = useRef(true)

  useEffect(() => {
    if (isFirst.current && experimentKit) {
      const cookies = parseCookies()

      Object.entries(cookies)
        .filter(([key]) => key.startsWith('exp-'))
        .reduce((acc, [key, value]) => {
          const experimentKey = key.replace('exp-', '')
          experimentKit.split(experimentKey)
          return {
            ...acc,
            [experimentKey]: value,
          }
        }, {})

      if (data) {
        experimentKit.setAttributes(data.attributes)
        experimentKit.setExperiments(data.experiments)
        experimentKit.setDisabledExperiments(data.disabledExperiments)
      }

      if (preAssignedSSRExperiments) {
        experimentKit.setAssigned(preAssignedSSRExperiments)
      }

      isFirst.current = false
    }
  }, [experimentKit, data, preAssignedSSRExperiments])

  return experimentKit
}

export function useExperiment<T extends JSONValue = any>(
  key: string
): ExperimentResult<T | null> {
  const ek = useExperimentKit()
  return experiment(key, ek)
}

export function useExperimentValue<T extends JSONValue = any>(
  key: string
): WidenPrimitives<T> {
  const ek = useExperimentKit()
  return ek ? ek.getExperimentValue(key) : null
}

export function useExperimentSplit(
  key: string,
  forcedVariant?: string
): string {
  const ek = useExperimentKit()
  if (forcedVariant) {
    ek.forceVariant(key, forcedVariant)
  }
  return ek ? ek.split(key) : null
}

export const ExperimentKitProvider: FC<
  PropsWithChildren<{ experimentKit?: ExperimentKit }>
> = ({ children, experimentKit }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRenderCount] = useState(0)

  useEffect(() => {
    if (!experimentKit || !experimentKit.setRenderer) return

    experimentKit.setRenderer(() => {
      setRenderCount(rc => rc + 1)
    })

    return () => experimentKit.setRenderer(() => {})
  }, [experimentKit])

  return (
    <ExperimentKitContext.Provider value={{ experimentKit }}>
      {children}
    </ExperimentKitContext.Provider>
  )
}
